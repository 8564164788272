<template>
  <div class="">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}
</style>
