import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Demo from '../components/Demo.vue'
import MedQCare from '../components/MedQCare.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo
  },
  {
    path: '/',
    name: 'MedQCare',
    component: MedQCare
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
