<template>
  <div class="row wrapper">
    <div class="wrapper-header">
      <div class="row wrapper-head-content">
        <div class="col-4">
          <img src="../assets/LOGO.png" class="logo-medqcare">
        </div>
      </div>
      <p class="title-head-main">
        Dapatkan Demo Gratis
      </p>
      <p class="tetle-head-above">
        Dapatkan pengalaman menarik dengan akses demo gratis untuk Anda
      </p>
    </div>
    <div class="wrapper-form">
      <div class="form row">
        <label class="label-form">
          Nama Klinik / Perusahaan
        </label>
        <input type="text" class="input-form" placeholder="Cth: Klinik Medika">
        <label class="label-form">
          Email Klinik / Perusahaan
        </label>
        <input type="text" class="input-form" placeholder="Cth: email@medika.id">
        <label class="label-form">
          Jabatan
        </label>
        <input type="text" class="input-form" placeholder="Contoh: HRD">
        <label class="label-form">
          Nomor HP
        </label>
        <input type="text" class="input-form" placeholder="+6281298403976">
        <label class="label-form">
          Pesan (Opsional)
        </label>
        <textarea type="text" class="input-form-big" placeholder="Pesan Anda" />
        <button
         class="button-submit"
        >
          Dapatkan Akses
        </button>
      </div>
    </div>
    <div class="wrapper-footer">
      <div class="wrapper-footer-above row">
        <div class="col-8">
          <img src="../assets/Logo MQCare Grey.png" class="logo-medqcare-grey">
        </div>
        <p class="address-title col-2">
          Rukan Rose Garden 1 No. 7 Bekasi Selatan 17148
        </p>
        <p class="telp-title col-2">
          (021) 82732832 info@medqcare.id
        </p>
      </div>
      <div class="line-footer" />
      <div class="wrapper-footer-bellow row">
        <div class="col-6">
          <p class="copyright-title">
            Copyright @ MedQCare 2022. All Rights Reserved.
          </p>
        </div>
        <div class="col-6 text-end wrapper-link-footer">
          <a>
            About
          </a>
          <a>
            Service
          </a>
          <a>
            FAQ's
          </a>
          <a>
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Demo'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


.wrapper {
  margin: 0;
}

.wrapper-header {
  padding: 0;
  margin: 0;
}

.wrapper-head-content {
  margin: 2vw 4.5vw 4vw 4.5vw;
}

.logo-medqcare {
  width: 30%;
  height: 2vw;
}

.title-head-main {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 2.1vw;
  text-align: center;
  line-height: 4vw;
  color: #3D4242;
  margin-bottom: 1.5vw;
}

.tetle-head-above {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6vw;
  line-height: 1vw;
  text-align: center;
  color: #3D4242;
  margin-bottom: 3vw;
}

.wrapper-form {
  background: #ECF1FD;
  padding-top: 3.5rem;
  padding-bottom: 4.5rem;
}

.form {
  margin: auto;
  width: 43vw;
  background: #FFFFFF;
  padding: 0 1.7vw;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
}

.label-form {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 1vw;
  align-items: center;
  color: #333333;
  margin-top: 1.7rem;
  padding: 0;
}

.input-form {
  background: #F5F5F5;
  border-radius: 0.4vw;
  text-decoration: none;
  outline: none;
  font-size: 1vw;
  line-height: 1vw;
  border: #F5F5F5;
  height: 3vw;
  margin-top: 0.5rem;
}

.input-form-big {
  background: #F5F5F5;
  border-radius: 0.4vw;
  text-decoration: none;
  outline: none;
  font-size: 1vw;
  line-height: 1vw;
  border: #F5F5F5;
  height: 7vw;
  margin-top: 0.5rem;
  margin-bottom: 1.7rem;
  padding: 12px 12px;
  resize: none;
  white-space: nowrap;
}

.input-form-big::-webkit-scrollbar {
  height: 6px;
  border-radius: 50%;
}

.input-form-big::-webkit-scrollbar-thumb {
  background: #E7E7E7;
}

.button-submit {
  background: #00A4AB;
  border-radius: 0.3vw;
  border: #00A4AB;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 1vw;
  text-align: center;
  color: #FFFFFF;
  padding: 0.8vw;
  width: 10vw;
  margin-bottom: 1.7vw;
}

.wrapper-footer {
  background: #040A18;
  padding: 3vw 6vw 5vw 6vw;
}

.wrapper-footer-above {
  margin-bottom: 4.3vw;
}

.logo-medqcare-grey {
  width: 10vw;
}

.address-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.01vw;
  line-height: 160%;
  text-align: right;
  color: #707070;
}

.telp-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.01vw;
  line-height: 160%;
  text-align: right;
  color: #707070;
}

.line-footer {
  border-top: 1px solid #282828;
  margin-bottom: 2vw;
}

.copyright-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.01vw;
  line-height: 160%;
  color: #3D4242;
}

.wrapper-link-footer a {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.03vw;
  margin-left: 2.5vw;
  line-height: 24px;
  color: #3D4242 !important;

}
</style>